import React, {useEffect, useState, FC, useMemo, useCallback} from "react";
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';

import './App.css'; // Tell webpack that Button.js uses these styles
import { async } from "@firebase/util";

import { Wallet } from "./Wallet";

import { clusterApiUrl, sendAndConfirmTransaction, Transaction, Connection, PublicKey, SystemProgram, LAMPORTS_PER_SOL } from '@solana/web3.js';


import { WalletAdapterNetwork, WalletNotConnectedError } from '@solana/wallet-adapter-base';
import {
    //LedgerWalletAdapter,
    PhantomWalletAdapter,
    //SlopeWalletAdapter,
   // SolflareWalletAdapter,
   // SolletExtensionWalletAdapter,
  //  SolletWalletAdapter,
   // TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
    WalletDisconnectButton,
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';
import { collection, getDocs, query, where, doc, addDoc, Timestamp, serverTimestamp} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCvgJ5H249dOvqaQ7Kl-u8Vf2V2JXIj3v0",
  authDomain: "serpent-ico.firebaseapp.com",
  projectId: "serpent-ico",
  storageBucket: "serpent-ico.appspot.com",
  messagingSenderId: "808401469696",
  appId: "1:808401469696:web:7d3ab4e47d0b9084d0f3e5",
  measurementId: "G-FN0TJBMLB7"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);



// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');



const App = () =>{
  const [transactions, setTransactions] =
  useState();

const conn = React.useRef();
const wallet = React.useRef();
const { connection } = useConnection();
const {  publickKey, sendTransaction } = useWallet();


// The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
const network = WalletAdapterNetwork["mainnet-beta"];


// You can also provide a custom RPC endpoint.
const endpoint = useMemo(() => clusterApiUrl(network), [network]);

// @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
// Only the wallets you configure here will be compiled into your application, and only the dependencies
// of wallets that your users connect to will be loaded.
const wallets = useMemo(
    () => [
        new PhantomWalletAdapter(),
       // new SlopeWalletAdapter(),
        //new SolflareWalletAdapter({ network }),
       // new TorusWalletAdapter(),
       // new LedgerWalletAdapter(),
        //new SolletWalletAdapter({ network }),
        //new SolletExtensionWalletAdapter({ network }),
    ],
    [network]);
  const [token, setToken] = useState([]);
  const [price, setPrice] = useState([]);



  var [theDoc, getTheDoc] = useState([]);
  var [theDoc2, getTheDoc2] = useState([]);
  const tokenReference = collection(db, "token")



  function handleChange(e){

if (e.target.name == "from"){
  if (e.target.value>0 && e.target.value>.1){
    console.log("from: "+e.target.value);
    //change to 
     
    theDoc = {quantity:e.target.value* 100000, price:e.target.value};
    theDoc2 = {price:e.target.value, quantity:e.target.value* 100000}
    setPrice(theDoc2)
    setToken(theDoc);
  }else{
    console.log("from: "+e.target.value);
    //change to 
     
    theDoc = {quantity:0, price:0};
    theDoc2 = {quantity:0, price:0};

    setToken(theDoc);
    setPrice(theDoc2);


  }
if (e.target.value <= .1){
  console.log("lower")
  theDoc = { quantity: 10000, price: .1}
  theDoc2 = { quantity: 10000, price: .1}

  
  setToken(theDoc)
  setPrice(theDoc2)


}

}
else if (e.target.name ="to"){
  console.log("to");
  if (e.target.value>0){
    console.log("from: "+e.target.value);
    //change to 
     
    theDoc = {quantity:Math.floor(e.target.value), price:e.target.value/100000};

    setToken(theDoc);
    setPrice(theDoc);

  }else{
    console.log("from: "+e.target.value);
    //change to 
     
    theDoc = {quantity:0, price:0};
    setToken(theDoc);
    setPrice(theDoc)

  }
  if (Math.floor(e.target.value/100000) < .1){
    theDoc = { quantity: 10000, price: .1}
    setToken(theDoc)
    setPrice(theDoc)
  
  }

}

   

  }
  //const { publicKey, sendTransaction } = useWallet();


     
  //const destPubkeyStr = "EVeEgzJBUb7CZohdeqFRe43hEC9grLyaGksQV7C5sKVb"
  //const lamports = token.price * LAMPORTS_PER_SOL;

  
  function connectWallet(){

    (async() => {
    try {
    const resp = await window.solana.connect();
   return wallet = resp;
    // 26qv4GCcx98RihuK3c4T6ozB3J7L6VwCuFVc7Ta2A3Uo 
} catch (err) {
    // { code: 4001, message: 'User rejected the request.' }
}
})();
console.log("connected");
}
const [show, setShow] = useState(false);
const [showSent, setShowSent] = useState(false);


async function setFirestore(walletDestiny){

  console.log("setFirestore");
  signInAnonymously(auth)
    .then(() => {
      // Signed in..
      // Add a new document with a generated id.

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    console.log("authenticated: "+uid)
    addDocument(walletDestiny)
    // ...
  } else {
    // User is signed out
    // ...
  }
});

console.log("signed in");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });

}





async function addDocument(walletDestiny){

  console.log(token.quantity)
  console.log(token.price)
  console.log(walletDestiny)
  console.log(serverTimestamp())
  const docRef = await addDoc(collection(db, "buy_serpent"), {
    quantity: token.quantity,
    price: token.price,
    wallet: walletDestiny,
    created_at: serverTimestamp(),
    sent: false
  });
  setShow(false)
  console.log("Document written with ID: ", docRef.id);
  
}

  async function signInTransactionAndSendMoney(){
if (theDoc.quantity <=10000 || theDoc.price<.1){
console.log("less than .1")
}

    (async() => {

        const network = "https://api.mainnet-beta.solana.com";
      //const network = WalletAdapterNetwork["mainnet-beta"];;
      
const connection = new Connection(network);
const transaction = new Transaction();

const lamports = token.price * LAMPORTS_PER_SOL;



try {
    const destPubkeyStr = "F65xgN7bUhaJffKhtFotQsZEgd8DLviWt59qBMA4LfC5"

    console.log("starting sendMoney");
    const destPubkey = new PublicKey(destPubkeyStr);
    const resp = await window.solana.connect();
   const wallet = resp;
    const walletAccountInfo = await connection.getAccountInfo(
        wallet.publicKey
    );
    console.log("wallet data size", walletAccountInfo?.data.length);

    const receiverAccountInfo = await connection.getAccountInfo(destPubkey);
    console.log("receiver data size", receiverAccountInfo?.data.length);
console.log("wallet"+wallet.publicKey);
    const instruction = SystemProgram.transfer({
      fromPubkey: wallet.publicKey,
      toPubkey: destPubkey,
      lamports, // about half a SOL
    });
    let trans = await setWalletTransaction(instruction, connection, wallet);
    console.log(wallet.publicKey)
    let signature = await signAndSendTransaction(wallet, trans, connection);
    setFirestore(wallet.publicKey+"");
    let result = await connection.confirmTransaction(signature, "singleGossip");
    console.log("money sent", result);
  } catch (e) {
    console.warn("Failed", e);
  }


    })()
 
     async function setWalletTransaction(
  instruction,connection,wallet
) {
  const transaction = new Transaction();
  transaction.add(instruction);
  transaction.feePayer = wallet.publicKey;
  let hash = await connection.getRecentBlockhash();
  console.log("blockhash", hash);
  transaction.recentBlockhash = hash.blockhash;
  return transaction;
}

 async function signAndSendTransaction(
  wallet,
  transaction,
  connection
) {
     // Sign transaction, broadcast, and confirm
     setShow(true)
    const { signature } = await window.solana.signAndSendTransaction(transaction);
await connection.confirmTransaction(signature);

setShowSent(true)
  //let signedTrans = await wallet.signTransaction(transaction);
  console.log("sign transaction");
  //let signature = await connection.sendRawTransaction(signedTrans.serialize());
  console.log("send raw transaction");
  return signature;
}

}
  
  useEffect(()=> {
   
    
    const getTokenPrice = async() => {

      const q = query(tokenReference, where("name", "==", "serpent"));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
       // console.log(doc.id, " => ", doc.data());
        setToken(doc.data());
        theDoc = doc.data();
        setPrice(doc.data());
        theDoc2 = doc.data();
      });
    }

 


    
    getTokenPrice();
  }, []);


  return (
   <div>
      <nav className="navbar">
        <a href="/"><img width="65px" className="navbar-brand" src="/img/serpent_logo.png" alt="Serpent Academy coin"/>
        </a>
  
        </nav>
        <nav className="navbar">
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <WalletMultiButton />
                    <WalletDisconnectButton />
                    { /* Your app's components go here, nested within the context providers. */ }
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
        </nav>
    <br></br>
    <div className="card swap">
    <div defaultValue={token.loader} style={{ display: show ? "block" : "none" }} className="loader"></div>
    <div defaultValue={token.loaderText} style={{ display: show ? "block" : "none" }} >Wait until transaction is confirmed you will be redirected</div>

    <div defaultValue={token.tokenSent} style={{display: showSent ? "block" : "none"}} className="alert">
  <strong>Successs!</strong> $serpent token sent.
</div>
<div defaultValue={token.rules}  className="alert">
  
  <strong>Minimum $serpent buy!</strong> .1 SOL.
</div>
    <div className="card">
     <h3 className="card-title">Serpent Coin $SERPENT</h3>

      <p className="card-description">You need  $SERPENT (2 SOL) to be able to earn in our apps.</p>
      </div>
    <div className="form-group"><label className="form-label">SOL</label>
    <input min=".1" onChange={handleChange} className="form-input" name="from" type="number" defaultValue={price.price}/></div>
    <br/>
    <div  className="form-group"><label className="form-label">SERPENT</label>
    <input disabled className="form-input" name="token" type="number" defaultValue={token.quantity} onChange={ handleChange }/></div>
    <br/>
    <button onClick={signInTransactionAndSendMoney} className="wallet-adapter-button form-btn" tabIndex="0" type="button" >Exchange</button>
   
  </div></div>
  );
}
export default App;